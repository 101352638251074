<template>
	<section class="section">
		<div class="content">
			<h3 class="page-title">
				Пользовательское соглашение
			</h3>

			<div class="text dark sm">
				<br>
				<p>Текст настоящего пользовательского соглашения (далее &ndash; Соглашение), постоянно размещенный в сети Интернет по адресу www.lift-bf.ru и www.lk.lift-bf.ru, содержит все существенные условия и является предложением <b>Благотворительного фонда &laquo;Система&raquo;</b> (ОГРН 1047796025419; ИНН 7704510770; адрес места нахождения: 127006, г. Москва, ул. Малая Дмитровка, д.18, стр.1) (далее &mdash; Фонд) в благотворительных целях содействия деятельности в сфере образования, науки и просвещения заключить соглашение с любым третьим лицом (далее &ndash; Пользователь), использующим Сайт на указанных в тексте настоящего пользовательского соглашения условиях. Текст настоящего пользовательского соглашения является публичной офертой в соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации. </p>
				<p>Надлежащим акцептом настоящей оферты в соответствии со статьей 438 Гражданского кодекса Российской Федерации считается последовательное осуществление Пользователем в совокупности всех следующих действий: </p>
				<ul>
					<li>Ознакомление с условиями настоящего пользовательского соглашения;</li>
					<li>Внесение достоверных и актуальных сведений, принадлежащий Пользователю, в регистрационную форму, расположенную на сайте www.lift-bf.ru во вкладке «Войти → Зарегистрироваться сейчас, или на Промосайте stars.lift-bf.ru, lesson.lift-bf.ru, lift-bf.ru/ambassadors;</li>
					<li>Проставление символа в специальном поле под заголовком &laquo;Я принимаю условия пользовательского соглашения&raquo; в регистрационной форме; </li>
					<li>Нажатие кнопки &laquo;Зарегистрироваться&raquo; в заполненной регистрационной форме; </li>
					<li>Подтверждение адреса электронной почты путем перехода по ссылке, направленной на указанный при регистрации адрес электронной почты.</li>
				</ul>
				<p>С момента перехода по ссылке, направленной на указанный при регистрации адрес электронной почты, регистрация на Сайте считается завершенной, оферта надлежащим образом акцептованной, а условия настоящего пользовательского соглашения обязательными для зарегистрированного лица. </p>
				<p>Регистрируясь на Сайте, Пользователь выражает согласие Фонду, Партнерам, а также третьим лицам, привлеченным Фондом для реализации функционирования Сайта, на сбор, хранение, использование, передачу и иную обработку сведений, ставших известными в процессе пользования Сайтом, также на обработку статистической информации и проведения маркетинговых исследований. </p>
				<p>
					Список третьих лиц, привлеченных Фондом для реализации функционирования Сайта, размещен по адресу: <a
						href="/agreement-annex"
						target="_blank"
					>www.lift-bf.ru/agreement-annex</a>.
				</p>
				<br>
				<p><b>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</b></p>
				<p>1.1. В настоящем Соглашении, если из его текста прямо не вытекает иное, следующие термины будут иметь указанные ниже значения:&nbsp;</p>
				<table>
					<tbody>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Администратор или Фонд</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>
									Благотворительный фонд «Система» (ОГРН 1047796025419;
									ИНН 7704510770; адрес места нахождения: 127006, г. Москва,
									ул. Малая Дмитровка, д.18, стр.1)
								</p>
							</td>
						</tr>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Пользователь</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>
									Любое физическое лицо, принявшее настоящее соглашение,
									зарегистрированное на Сайте, получившее доступ к Сайту
									посредством сети Интернет, достигшее возраста, допустимого
									в соответствии с законодательством Российской Федерации
									для акцепта настоящей публичной оферты, и обладающее
									соответствующими полномочиями
								</p>
							</td>
						</tr>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Авторизация</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>
									Введение Пользователем своего логина и пароля для входа в
									свой Личный кабинет на Сайте
								</p>
							</td>
						</tr>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Правообладатель</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>
									Организация, обладающая исключительным правом на
									результаты интеллектуальной деятельности в виде
									видеокурсов и иных материалов, размещенных на Сайте, и
									заключившая с Администратором лицензионный договор о предоставлении Администратору права использования
									материалов способами и в объеме, которые необходимы для
									освоения (просмотра, иного использования) Пользователям
									указанных материалов.
								</p>
							</td>
						</tr>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>
									Партнер (Потенциальный
									работодатель)
								</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>
									Юридическое лицо, размещающее вакансии на Сайте с целью
									поиска квалифицированных трудовых кадров
								</p>
							</td>
						</tr>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Видеокурсы</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>
									Совокупность аудиовизуальных (видеолекции),
									текстовых, графических материалов и программ для ЭВМ
									(программные средства для выполнения тестовых заданий),
									объединенных общей темой, предназначенных для обучения
									Пользователя с целью получения ими новых или развития
									имеющихся навыков и знаний по указанной теме.
									Видеокурс не является полностью или в части образовательной
									программой.
								</p>
							</td>
						</tr>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Личный кабинет</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>
									Совокупность защищенных страниц Сайта, созданных в
									результате регистрации Пользователя, используя которые
									Пользователь имеет возможность доступа к Видеокурсам,
									возможность изменения сведений о Пользователе, пароля, а
									также осуществления иных действий, предусмотренных
									функционалом Личного кабинета.
								</p>
								<p>
									Доступ в Личный кабинет осуществляется путем внесения
									учетных данных в предусмотренные для этого поля на Сайте.
								</p>
							</td>
						</tr>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Промосайт</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Интернет-страница, размещенная по адресу stars.lift-bf.ru или lesson.lift-bf.ru, функционал которой позволяет осуществить регистрацию на Сайте при заполнении минимально необходимой информации о Пользователе</p>
							</td>
						</tr>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Резюме</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>
									Электронный документ, содержащий персональные данные
									Пользователя, включающий информацию о фамилии и имени,
									адресе электронной почты, образовании, описании мест
									работы, а также прочих данных, относящихся к поиску работы
									и иных видов занятости
								</p>
							</td>
						</tr>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Сайт</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>
									Совокупность информации, текстов, графических элементов,
									дизайна, изображений, фото, аудио и видеоматериалов, и
									иных охраняемых результатов интеллектуальной деятельности
									и средств индивидуализации Администратора, а также
									программ для ЭВМ, содержащихся в информационной
									системе, обеспечивающей доступность такой информации в
									сети Интернет по сетевому адресу. www.lift-bf.ru и
									www.lk.lift-bf.ru.
								</p>
								<p>
									Сайт является Интернет-ресурсом, предназначенным
									для предоставления Пользователю информационных
									материалов с целью организации самообучения Пользователя
									навыкам по определенной теме или необходимых для освоения
									определенной специальности, а также для предоставления
									доступа к Сервису поиска вакансий Партнеров.
								</p>
							</td>
						</tr>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Сервис поиска вакансий</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>
									Раздел Сайта, расположенный по сетевому адресу www.liftbf.ru и www.lk.lift-bf.ru, предназначенный для
									помощи Пользователям в профессиональной ориентации и в
									трудоустройстве по результатам использования ресурсов,
									размещенных на Сайте, а также предназначенный для помощи
									работодателям в поиске квалифицированных сотрудников
									среди Пользователей.
								</p>
							</td>
						</tr>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Соглашение</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Настоящее пользовательское соглашение</p>
							</td>
						</tr>
						<tr>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Стороны</p>
							</td>
							<td
								colspan="1"
								rowspan="1"
							>
								<p>Администратор и Пользователь</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p>1.2. Все остальные термины и определения, используемые в тексте Соглашения, толкуются Сторонами в соответствии с законодательством Российской Федерации и сложившимися в сети Интернет обычными правилами толкования соответствующих терминов. </p>
				<p>1.3. Названия заголовков (статей) Соглашения предназначены исключительно для удобства пользования текстом Соглашения и буквального юридического значения не имеют. </p>
				<br>
				<p><b>2. ПРЕДМЕТ СОГЛАШЕНИЯ</b></p>
				<p>2.1. Администратор предоставляет Пользователю право на безвозмездной основе использовать Сайт, личный кабинет, сервисы Сайта и материалы, размещенные на Сайте, по их прямому назначению, в том числе путем осуществления доступа к Сайту, Личному кабинету с помощью персональных компьютеров и мобильных устройств и использования явных функций Сайта, личного кабинета на условиях безвозмездной простой (неисключительной) лицензии на территории доступа к Сайту, личному кабинету и к их функциям, на срок, в течение которого Сайт, личный кабинет и их функции остаются доступны для Пользователя. </p>
				<p>2.2. Администратор оказывает Пользователю услуги по предоставлению доступа к ресурсам Сайта с целью организации самообучения Пользователя навыкам по определенной теме или необходимых для освоения определенной специальности, а также предоставляет доступ к Сервису поиска вакансий Партнеров. </p>
				<br>
				<p><b>3. ПОРЯДОК РЕГИСТРАЦИИ НА САЙТЕ</b></p>
				<p>3.1. Администратор предоставляет Пользователю до его регистрации на Сайте доступ к общей информации о Сайте, видеокурсах и иных материалах, размещенных на Сайте. </p>
				<p>
					3.2. После регистрации на Сайте Пользователю на безвозмездной основе предоставляется доступ ко всему функционалу, видеокурсам и иным материалам, размещенным на Сайте, а также доступ к профориентационным тестам и вакансиям Партнеров, к иным функциям Сайта и Личного кабинета, предоставляемым явно.
				</p>
				<p>3.3. После того, как Пользователь заполнил все необходимые поля формы регистрации на Промосайте по сетевому адресу stars.lift-bf.ru, lift-bf.ru/ambassadors или lesson.lift-bf.ru, или Сайте по сетевому адресу www.lift-bf.ru и www.lk.lift-bf.ru.</p>
				<p>3.4. По завершении регистрации Пользователю предоставляется доступ к Личному кабинету по учетным данным &ndash; адресу электронной почты и паролю. Пароль может быть изменен Пользователем в любое время после регистрации. </p>
				<p>3.5. На Пользователе лежит обязанность обеспечить безопасность и сохранность пароля. При утрате либо компрометации пароля, а также в случае незаконного завладения третьими лицами доступа к Личному кабинету, Пользователь обязан незамедлительно сообщить об этом Администратору на адрес электронной почты help@lift-bf.ru. До момента поступления такого сообщения все действия, совершенные с использованием Личного кабинета Пользователя, будут считаться совершенными самим Пользователем. </p>
				<p>3.6. Пользователь на используемом им аппаратно-программном обеспечении может разрешить хранение логина и пароля (с использованием файлов cookies или аналогичных технологий) для последующей автоматической авторизации на Сайте. </p>
				<p>3.7. Учитывая тот факт, что Администратор не может проверить достоверность информации, предоставляемой Пользователем при регистрации, считается, что Пользователи предоставляют достоверную и достаточную информацию, поддерживает эту информацию в актуальном состоянии, а Администратор использует ее в рамках функциональных возможностей Сайта. </p>
				<br>
				<p><b>4. ПОРЯДОК ПОЛЬЗОВАНИЯ ЛИЧНЫМ КАБИНЕТОМ И САЙТОМ</b></p>
				<p>4.1. В Личном кабинете Пользователя отображается информация о текущих и пройденных Видеокурсах. </p>
				<p>4.2. Для доступа к определенным Видеокурсам и иным ресурсам Сайта от Пользователя может потребоваться соблюдение специальных технических требований Администратора, размещенных на Сайте.</p>
				<p>4.3. Пользователь обязан пользоваться Сайтом и Личным кабинетом добросовестно, не нарушая законодательство Российской Федерации, права и свободы третьих лиц, нормы морали и нравственности. </p>
				<p>4.4. Пользователь самостоятельно обеспечивает доступ в Интернет, а также настройку и защиту от несанкционированного использования своего аппаратно-программного обеспечения, а также оперативно реагирует при обнаружении случаев такого использования. </p>
				<p>4.5. Пользователь принимает полную ответственность за все действия, предпринятые на Сайте и в Личном кабинете. </p>
				<br>
				<p><b>5. УСЛОВИЯ И ПОРЯДОК ДОСТУПА К ВИДЕОКУРСАМ</b></p>
				<p>5.1. Пользователю доступна информация о каждом Видеокурсе, его описание, о темах и продолжительности занятий, о продолжительности курса, количестве и названии материалов (аудиовизуальных, текстовых, графических) в составе курса, о количестве и составе слушателей, уже прошедших курс, о рейтинге курса. </p>
				<p>5.2. Доступ к каждому Видеокурсу предоставляется Пользователям через личный кабинет.</p>
				<p>5.3. Видеокурсы включают в себя видеолекции и в ряде случаев программные средства для проверки Пользователем усвоенной информации, предоставленной в видеолекции (тесты). Тестирование происходит в автоматическом режиме программными средствами Сайта. Положительным результатом тестирования является выбор Пользователем правильного ответа из числа предложенных. </p>
				<p>5.4. По результатам просмотра Видеокурсов (успешного прохождения тестирования) Пользователю предоставляется соответствующий электронный сертификат с указанием темы курса, а также данных Пользователя в соответствии с данными из Личного кабинета. </p>
				<p>5.5 По завершении прохождения Видеокурса Пользователь вправе оценить курс и оставить комментарий о курсе. Администратор оставляет за собой право удалить комментарии Пользователей, признанные Администратором противоречащими законодательству Российской Федерации, а также содержащие нецензурные слова и выражения или оскорбляющие пользователей Сайта и иных третьих лиц. </p>
				<br>
				<p><b>6. СЕРВИС ПОИСКА ВАКАНСИЙ</b></p>
				<p>6.1. Сервис поиска вакансий, размещенный на Сайте, включает в себя тестирование на профориентацию и перечень вакансий, публикуемый Администратором на основании данных, получаемых от Партнеров. </p>
				<p>6.2. Тестирование на профориентацию включает в себя: серию вопросов, ответы на которые используются для формирования предположений о потенциале развития пользователя в определенных профессиях. По результатам тестирования Пользователь получает список профессий, рекомендованных к освоению. </p>
				<p>6.3. В разделе &laquo;Вакансии&raquo; Пользователь может выбрать любую из предложенных вакансий и отправить свое Резюме в виде прилагаемого файла потенциальному работодателю. </p>
				<p>6.4. Нажимая кнопку &laquo;Отправить&raquo; в форме Резюме в разделе &laquo;Найти работу&raquo;, Пользователь в соответствии с Федеральным законом от 27.07.2006 г. № 152-ФЗ &laquo;О персональных данных&raquo; предоставляет Администратору и потенциальному работодателю согласие на обработку персональных данных, указанных Пользователем в соответствующем Резюме, согласно Политике обработки и защиты персональных данных, размещенной на Сайте, в том числе на поиск и сбор информации о Пользователе из общедоступных источников информации на основе сведений, указанных в Резюме. </p>
				<p>6.5. Цель обработки персональных данных Пользователя при отправке Резюме включает в себя следующее: </p>
				<ul>
					<li>Возможность трудоустройства или иного вида занятости у потенциальных работодателей (Партнеров), а также проведение предварительного собеседования в целях трудоустройства или иного вида занятости у Партнеров, оказание услуг/выполнение работ Пользователем на основании договора гражданско-правового характера; </li>
					<li>Направление Партнером уведомлений, запросов и информации, контактирование иным способом с Пользователем, обработка запросов и обращений от Пользователя с целью трудоустройства. </li>
				</ul>
				<p>6.6. Срок обработки персональных данных Пользователя при отправке Резюме (срок предоставления согласия на обработку) неограничен и определяется самостоятельно Пользователем.</p>
				<p>6.7. Направление Пользователем Резюме не может рассматриваться как основание для установления между Пользователем и Администратором агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо не предусмотренных в настоящем соглашении. </p>
				<p>6.8. Направление Пользователем Резюме Партнеру не приравнивается и не означает формальное приглашение на работу к потенциальному работодателю и не влечет обязанности Партнера заключать трудовой договор или договор гражданско-правового характера с Пользователем.  </p>
				<p>6.9. В момент отправки Резюме Пользователь в целях достижения цели обработки персональных данных предоставляет согласие Администратору на распространение и передачу персональных данных Пользователя потенциальному работодателю (Партнеру), указанному в тексте вакансии (включая право на трансграничную передачу персональных данных).</p>
				<br>
				<p><b>7. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ И ОГРАНИЧЕНИЯ ПРИ ПОЛЬЗОВАНИИ САЙТОМ</b></p>
				<p>7.1. Используя Сайт, Пользователь признает и соглашается с тем, что все без изъятий содержимое Сайта (в том числе, но не ограничиваясь: аудиовизуальные произведения, текстовые и графические материалы, программы для ЭВМ, товарные знаки/знаки обслуживания, логотипы), структура содержимого Сайта, программный код Сайта и/или его частей являются результатами интеллектуальной деятельности, исключительное право на которые в полном объеме принадлежит Администратору или иным третьим лицам. Исключительное право, а также иные интеллектуальные права на вышеуказанные результаты интеллектуальной деятельности не переходят к Пользователю в результате пользования Сайтом и заключения Соглашения. </p>
				<p>7.2. Пользователю запрещается копировать, модифицировать, изменять, удалять, дополнять, публиковать, передавать содержащиеся на Сайте результаты интеллектуальной деятельности, создавать производные работы, изготавливать или продавать товары/оказывать услуги на их основе, воспроизводить, отображать или любым другим образом эксплуатировать или использовать такие результаты интеллектуальной деятельности без прямого разрешения Администратора. </p>
				<p>При цитировании материалов Сайта, если это прямо предусмотрено функциями Сайта, Пользователь обязуется указывать ссылку на страницу Сайта, на которой размещен соответствующий материал. </p>
				<p>7.3. Во избежание сомнений, Пользователю запрещается: </p>
				<ul>
					<li>копировать и/или распространять какую-либо информацию (включая части и компоненты видеокурсов, статей, логотипов и товарных знаков/знаков обслуживания), полученную на Сайте, кроме случаев, когда такая функция прямо предусмотрена на Сайте (например, наличие кнопок &laquo;Поделиться&raquo;/&laquo;Сделать репост&raquo;); </li>
					<li>использовать информацию, полученную на Сайте, для осуществления коммерческой деятельности, извлечения прибыли, либо для использования любым противоречащим закону способом, за исключением навыков, приобретенных на основе полученной в соответствии с Соглашением информации; </li>
					<li>копировать, либо иным способом использовать программную часть (программный код или часть кода) Сайта, а также его дизайн; </li>
					<li>размещать на Сайте персональные данные третьих лиц без их согласия, в том числе, но не ограничиваясь: адреса, телефоны, паспортные данные, адреса электронной почты; - размещать на Сайте любого вида рекламу, коммерческие предложения, агитационную информацию и любую другую информацию, кроме случаев, когда размещение такой информации согласовано с Администратором в письменной форме; </li>
					<li>изменять каким бы то ни было способом программную часть Сайта, совершать действия, направленные на изменение функционирования и работоспособности Сайта; - оскорблять и иным образом нарушать права и свободы других пользователей Сайта, третьих лиц, а также групп лиц; </li>
					<li>использовать нецензурную брань, осуществлять либо распространять информацию, содержащую призывы к массовым беспорядкам, осуществлению экстремистской деятельности, участию в массовых (публичных) мероприятиях, проводимых с нарушением установленного порядка, распространять информацию, необходимую для получения результатов интеллектуальной деятельности.</li>
				</ul>
				<br>
				<p><b>8. ОТВЕТСТВЕННОСТЬ ПОЛЬЗОВАТЕЛЯ</b></p>
				<p>8.1. В случае нарушения Пользователем условий Соглашения, законодательства Российской Федерации, норм морали и нравственности, либо технических требований Администратор вправе заблокировать или удалить личный кабинет, запретить либо ограничить доступ по учетным данным Пользователя к определенным или всем функциям и материалам Сайта. </p>
				<p>8.2. При выявлении случаев предоставления Пользователем доступа к личному кабинету третьим лицам Администратор по своему выбору применяет меры, предусмотренные пунктом 8.1. Соглашения. При этом Администратор вправе полностью заблокировать доступ Пользователя к личному кабинету или определенным материалам, размещенным на Сайте. </p>
				<p>8.3. Нарушение Пользователем условий Соглашения, повлекшее неблагоприятные последствия для Администратора (материальный или иной ущерб, административная и иная ответственность, предупреждения правоохранительных и иных органов исполнительной власти, претензии третьих лиц), является основанием для Администратора прекратить доступ Пользователя к Сайту и потребовать от Пользователя оплатить штраф в размере не менее расходов, понесенных Администратором на урегулирование споров, связанных с неправомерными действиями Пользователя, включая суммы штрафов, наложенных на Администратора уполномоченными государственными органами и убытков, возмещенных третьим лицам. </p>
				<p>8.4. В случае нарушения Пользователем интеллектуальных прав Администратора, а также иных ограничений по использованию Сайта Администратор вправе в любой момент без предупреждения заблокировать такому Пользователю полностью или частично доступ на Сайт и в Личный кабинет, а также, по своему усмотрению, обратиться к соответствующему Пользователю с требованием об устранении нарушений и/или потребовать устранить нарушения в судебном порядке. </p>
				<p>8.5. Администратор не отвечает за работоспособность Сайта и не гарантирует его бесперебойной работы. Администратор также не гарантирует сохранности информации, размещенной на Сайте и возможности бесперебойного доступа ко всем ресурсам и материалам, размещенным на Сайте. </p>
				<p>8.6. Пользователь использует Сайт в том виде, в каком он представлен, на собственный риск. Администратор не гарантирует Пользователю достижения каких-либо результатов вследствие использования Сайта. </p>
				<br>
				<p><b>9. ИНЫЕ УСЛОВИЯ</b></p>
				<p>9.1. Сайт может содержать ссылки на другие сайты в сети Интернет (сайты третьих лиц). Указанные третьи лица и содержание их сайтов/контента не проверяются Администратором на соответствие тем или иным требованиям (достоверности, полноты, законности и т.п.). Администратор не несет ответственности за любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ с использованием Сайта, в том числе, за любые мнения или утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а также за доступность таких сайтов или контента и последствия их использования Пользователем. </p>
				<p>9.2. Администратор не гарантирует, что Сайт соответствует требованиям Пользователя, что доступ к Сайту будет предоставляться бесперебойно и без ошибок, в том числе в результате действий (бездействия) третьих лиц. </p>
				<p>9.3. Программно-аппаратные ошибки как на стороне Администратора, так и на стороне Пользователя и любых третьих лиц, приведшие к невозможности получения Пользователем доступа к Сайту и/или ресурсам и материалам, размещенным на Сайте, являются обстоятельствами непреодолимой силы и основанием освобождения Администратора от любой ответственности за неисполнение обязательств Администратора. </p>
				<p>9.4. Администратор оставляет за собой право изменять публикуемые на Сайте материалы в любое время без предварительного уведомления. Ссылки на продукцию и услуги третьих лиц делается в информационных целях и не подразумевает, что Администратор их поддерживает или рекомендует. </p>
				<p>9.5. Все споры, разногласия и претензии, которые могут возникнуть в связи с заключением, исполнением, изменением, расторжением или признанием недействительным Соглашения, Стороны будут стремиться решить путем переговоров. Сторона, у которой возникли претензии и/или разногласия, направляет другой Стороне сообщение с указанием возникших претензий и/или разногласий. </p>
				<p>Сообщение Пользователя о возникших претензиях и/или разногласиях должно быть направлено Пользователем Администратору по электронному адресу info@lift-bf.ru, а также продублировано в письменном виде по адресу места нахождения Администратора, указанному в настоящем Соглашении.</p>
				<p>Сообщение Администратора о возникших претензиях и/или разногласиях направляется Администратором Пользователю по электронному адресу Пользователя, указанному при регистрации на Сайте, либо иным образом, позволяющим зафиксировать факт отправки. </p>
				<p>9.6. В случае, если ответ на сообщение не будет получен направившей сообщение Стороной в течение 30 (тридцати) рабочих дней с даты направления соответствующего сообщения, либо если Стороны не придут к соглашению по возникшим претензиям и/или разногласиям, спор подлежит разрешению в судебном порядке в Арбитражном суде города Москвы или в суде общей юрисдикции по месту нахождения Администратора. </p>
				<p>9.7. Администратор не несет ответственности за любые виды убытков, произошедшие вследствие использования Пользователем Сайта или отдельных частей/функций Сайта. </p>
				<p>9.8. При любых обстоятельствах ответственность Администратора в соответствии со статьей 15 Гражданского кодекса России ограничена 10 000 (десятью тысячами) рублей РФ и возлагается на него при наличии в его действиях вины. </p>
				<br>
				<p><b>10. ИЗМЕНЕНИЕ УСЛОВИЙ СОГЛАШЕНИЯ</b></p>
				<p>10.1. Администратор вправе в любой момент по своему усмотрению в одностороннем порядке изменять условия Соглашения, при этом такие изменения вступают в силу в момент опубликования новой версии Соглашения на Сайте. </p>
				<p>10.2. При каждом последующем посещении Сайта до начала пользования Личным кабинетом и/или иными функциональными возможностями Сайта Пользователь обязуется знакомиться с новой версией Соглашения. Продолжение использования Сайта и Личного кабинета будет означать согласие Пользователя с условиями новой версии Соглашения. </p>
				<p>10.3. Если Пользователь не согласен с условиями новой версии Соглашения, он обязан прекратить использование Личного кабинета на Сайте и любых материалов, размещенных на Сайте. </p>
				<br>
				<p><b>11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</b></p>
				<p>11.1. Соглашение и все возникающие из него правоотношения регулируются законодательством Российской Федерации. Все возникающие споры разрешаются в соответствии с законодательством Российской Федерации. </p>
				<p>11.2. Признание судом какого-либо положения Соглашения недействительным или не подлежащим исполнению не влечет за собой недействительности иных положений Соглашения. </p>
				<p>11.3. Бездействие со стороны Администратора в случае нарушения кем-либо из Пользователей положений Соглашения не лишает Администратора права предпринять позднее соответствующие действия в защиту своих интересов и защиту интеллектуальных прав на охраняемые в соответствии с законодательством результаты интеллектуальной деятельности, размещенные на Сайте. </p>
				<p>11.4. Пользователь подтверждает, что ознакомился со всеми положениями Соглашения, понимает и принимает их без каких-либо оговорок.</p>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
    }
}
</script>
